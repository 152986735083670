<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :title="dialogTitle"
    width="50%"
    :before-close="handleClose"
  >
    <el-form
      ref="formRef"
      v-loading="formLoading"
      :model="formData"
      :rules="formRules"
      label-width="120px"
    >
      <el-form-item label="盘厂图片：" prop="image">
        <FormImgUpload
          :url="formData.image"
          @upload="upload"
          :limit="1"
          :maxSize="20"
          :accept="'.png,.jpg,.jpeg,.svg,.tiff'"
          :multiple="true"
          :disabled="formType == 'view'"
        />
      </el-form-item>
      <el-form-item label="盘厂名称" prop="name">
        <el-input
          v-model="formData.name"
          placeholder="请输入"
          style="width: 400px"
          maxlength="50"
          :disabled="formType == 'view'"
        ></el-input>
      </el-form-item>
      <el-form-item label="盘厂简称" prop="abbr">
        <el-input
          v-model="formData.abbr"
          placeholder="请输入"
          style="width: 400px"
          maxlength="4"
          :disabled="formType == 'view'"
        ></el-input>
      </el-form-item>

      <el-form-item label="权益分类" prop="equityClassifyIds">
        <el-checkbox-group
          v-model="formData.equityClassifyIds"
          @change="handleSort"
          :disabled="formType == 'view'"
        >
          <el-checkbox
            v-for="item in classifyList"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>

      <div
        class="flex video_box"
        v-for="(item, index) in formData.equityClassifyList"
        :key="index"
      >
        <div class="flex">
          <span class="video_span" style="display: inline-block"
            ><span style="color: #f56c6c">* </span>{{ item.name }}
            <span class="marginLeft10">证书</span>
          </span>
          <FormImgUpload
            :url="item.certImage"
            @upload="uploadEquity"
            :limit="1"
            :maxSize="20"
            :accept="'.png,.jpg,.jpeg,.svg,.tiff'"
            :multiple="true"
            :indexof="index"
            :disabled="formType == 'view'"
          />
        </div>
        <div>
          <div>
            <span class="video_span" style="display: inline-block"
              ><span style="color: #f56c6c">* </span>签约日期
            </span>
            <el-date-picker
              v-model="item.startTime"
              type="date"
              :clearable="true"
              placeholder="选择日期"
              :disabled="formType == 'view'"
            />
          </div>
          <div>
            <span
              class="video_span"
              style="display: inline-block; margin-top: 20px"
              ><span style="color: #f56c6c">* </span>到期日期
            </span>
            <el-date-picker
              v-model="item.endTime"
              type="date"
              :clearable="true"
              placeholder="选择日期"
              :disabled="formType == 'view'"
            />
          </div>
        </div>
      </div>
      <!-- </el-form-item> -->
      <!-- <el-form-item label="合作模式" prop="cooperate">
        <el-input
          v-model="formData.cooperate"
          placeholder="请输入"
          style="width: 400px"
          maxlength="20"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="联系人" prop="contacts">
        <el-input
          v-model="formData.contacts"
          placeholder="请输入"
          style="width: 400px"
          maxlength="20"
          :disabled="formType == 'view'"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="phone">
        <el-input
          v-model="formData.phone"
          placeholder="请输入"
          style="width: 400px"
          maxlength="11"
          :disabled="formType == 'view'"
        ></el-input>
      </el-form-item>

      <el-form-item label="所在地" prop="address">
        <el-input
          v-model="formData.address"
          placeholder="请输入"
          style="width: 400px"
          maxlength="10"
          :disabled="formType == 'view'"
        ></el-input>
      </el-form-item>
      <el-form-item label="总授权数" prop="totalAuthNum">
        <el-input-number
          v-model="formData.totalAuthNum"
          placeholder="请输入"
          style="width: 400px"
          :max="999999"
          :min="0"
          controls-position="right"
          :disabled="formType == 'view'"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="已用授权数" prop="usedAuthNum">
        <el-input-number
          v-model="formData.usedAuthNum"
          placeholder="请输入"
          style="width: 400px"
          :max="999999"
          :min="0"
          controls-position="right"
          :disabled="formType == 'view'"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="flex" style="justify-content: center">
        <el-button
          :disabled="formLoading"
          type="primary"
          @click="submitForm"
          v-if="formType != 'view'"
          >确 定</el-button
        >
        <el-button @click="dialogVisible = false">
          {{ formType == "view" ? "关 闭" : "取 消" }}</el-button
        >
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { Message } from "element-ui";
import {
  factoryManageAdd,
  factoryManageUpdate,
  factoryManageDetail,
} from "@/api/factory/manage";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import { mobilePhoneRule } from "@/utils/formRules";
import dayjs from "dayjs";

export default {
  name: "index",
  components: {
    FormImgUpload,
  },
  props: {
    classifyList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      dialogVisible: false, // 弹窗的是否展示
      dialogTitle: "", // 弹窗的标题
      formLoading: false, // 表单的加载中：1）修改时的数据加载；2）提交的按钮禁用
      formType: "", // 表单的类型：create - 新增；update - 修改
      formData: {
        equityClassifyIds: [],
        name: "",
        image: [],
        cooperate: "",
        phone: "",
        contacts: "",
        address: "",
        equityClassifyList: [],
        abbr: "",
        usedAuthNum: "",
        totalAuthNum: "",
      },
      formRules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        abbr: [{ required: true, message: "请输入", trigger: "blur" }],
        equityClassifyIds: [
          { required: true, message: "请选择", trigger: "blur" },
        ],
        cooperate: [{ required: true, message: "请输入", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入", trigger: "blur" },
          ...mobilePhoneRule,
        ],
        contacts: [{ required: true, message: "请输入", trigger: "blur" }],
        address: [{ required: true, message: "请输入", trigger: "blur" }],
        totalAuthNum: [{ required: true, message: "请输入", trigger: "blur" }],
        usedAuthNum: [{ required: true, message: "请输入", trigger: "blur" }],
        image: [
          // {
          //   required: true,
          //   message: "请上传",
          //   trigger: "blur",
          // },
          // {
          //   validator: (rule, value, callback) => {
          //     if (value.length === 0) {
          //       callback(new Error("请上传"));
          //     } else {
          //       callback();
          //     }
          //   },
          // },
        ],
      },
    };
  },
  created() {},
  methods: {
    // const formRef = ref() // 表单 Ref
    /** 打开弹窗 */
    async open(type, id) {
      const userAgent = navigator.userAgent;
      const isSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
      const isWindows = /Windows/.test(userAgent);

      console.log("Is Safari:", isSafari);
      console.log("Is Windows:", isWindows);
      this.dialogVisible = true;
      this.dialogTitle =
        type === "view" ? "详情" : type === "create" ? "新增" : "修改";
      this.formType = type;
      this.resetForm();
      // 修改时，设置数据
      if (id) {
        this.formLoading = true;
        try {
          const { data } = await factoryManageDetail(id);

          this.formData = {
            ...data,
            equityClassifyIds: data.equityClassifyIds.split(","),
            image: data.image ? data.image.split(",") : [],
            equityClassifyList: data.equityClassifyList.map((item) => {
              return {
                ...item,
                certImage: item.certImage.split(","),
                id: item.equityClassifyId,
                name: item.equityClassifyName,
                endTime: isSafari
                  ? item.endTime.replace(/-/g, "/")
                  : item.endTime,
                startTime: isSafari
                  ? item.startTime.replace(/-/g, "/")
                  : item.startTime,
              };
            }),
            // image: [data.image],
          };
        } finally {
          this.formLoading = false;
        }
      }
    },
    async submitForm() {
      // 校验表单

      if (!this.$refs.formRef) return;
      // console.log(this.$refs.formRef.$refs.value, "zhe;li");
      const valid = await this.$refs.formRef.validate();

      if (!valid) return;

      try {
        this.formData.equityClassifyList.map((item) => {
          if (item.certImage.length === 0) {
            throw "请上传证书";
          }
          console.log(item.startTime, "日期", item);

          if (item.startTime === "") {
            throw "请选择签约日期";
          }
          if (item.endTime === "") {
            throw "请选择到期日期";
          }
          if (!this.validateDates(item.startTime, item.endTime)) {
            throw "签约日期不能晚于到期日期";
          }
        });
        if (this.formData.usedAuthNum > this.formData.totalAuthNum) {
          throw "已用授权数不能大于总授权数";
        }
        // 进行提交请求;
        const selectedItems = this.classifyList.filter((item) =>
          this.formData.equityClassifyIds.includes(item.id)
        );

        // 根据 classifyList 中的 index 字段对选中的项目进行排序
        selectedItems.sort((a, b) => a.index - b.index);
        const newData = {
          ...this.formData,
          image:
            this.formData.image && this.formData.image.length
              ? this.formData.image.join(",")
              : "",
          equityClassifyIds: selectedItems.map((item) => item.id).join(","),
          equityClassifyList: this.formData.equityClassifyList.map((item) => {
            return {
              ...item,
              certImage: item.certImage.join(","),
              endTime: dayjs(item.endTime).format("YYYY-MM-DD 23:59:59"),
              startTime: dayjs(item.startTime).format("YYYY-MM-DD 00:00:00"),
              equityClassifyId: item.id,
              equityClassifyName: item.name,
            };
          }),
        };
        console.log(newData, "this.formData");

        this.formLoading = true;
        if (this.formType === "create") {
          const res = await factoryManageAdd(newData);
          if (res.code == 200) {
            Message.success("新增成功");
          }
        } else {
          const res = await factoryManageUpdate(newData);
          if (res.code == 200) {
            Message.success("修改成功");
          }
        }
        this.dialogVisible = false;
        // 发送操作成功的事件
        this.$emit("success");
        // setTimeout(() => {
        this.formLoading = false;
        // }, 1000);
      } catch (e) {
        this.$message.error(e);
        this.formLoading = false;
      } finally {
      }
    },
    // 校验时间
    validateDates(dateA, dateB) {
      // 将日期字符串转换为日期对象
      const a = new Date(dateA);
      const b = new Date(dateB);
      console.log(a <= b, "xxx");

      // 比较日期，如果日期a晚于日期b，则返回false，否则返回true
      return a <= b;
    },
    //图片上传
    upload(e) {
      if (Array.isArray(e)) {
        this.formData.image = [...e];
      } else {
        this.formData.image = [...this.formData.image, e];
      }
    },
    // 权益等级相关数组信息
    uploadEquity(e, indexof) {
      if (Array.isArray(e)) {
        this.formData.equityClassifyList = this.formData.equityClassifyList.map(
          (item, index) => {
            if (index == indexof) {
              return {
                ...item,
                certImage: [...e],
              };
            } else {
              return item;
            }
          }
        );
      } else {
        this.formData.equityClassifyList = this.formData.equityClassifyList.map(
          (item, index) => {
            if (index == indexof) {
              return {
                ...item,
                certImage: [e],
              };
            } else {
              return item;
            }
          }
        );
      }
    },
    // 重置
    resetForm() {
      this.formData = {
        equityClassifyIds: [],
        image: [],
        equityClassifyList: [],
      };
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 处理权益顺序，按照 L1 L2 L3等排列，进行证书，时间的上传
    handleSort(e) {
      console.log(e, "打印");
      // 根据选中的 ID 数组 e，获取对应的 classifyList 中的项目
      const selectedItems = this.classifyList.filter((item) =>
        e.includes(item.id)
      );

      // 根据 classifyList 中的 index 字段对选中的项目进行排序
      selectedItems.sort((a, b) => a.index - b.index);
      console.log(selectedItems, "selectedItemsselectedItems");

      // 根据选中项目更新 equityClassifyList
      const newList = [];

      this.classifyList.forEach((classifyItem) => {
        const existingItem = this.formData.equityClassifyList.find(
          (item) => item.id === classifyItem.id
        );

        const selectedItem = selectedItems.find(
          (item) => item.id === classifyItem.id
        );

        // e.includes(item.id)

        if (existingItem) {
          newList.push(existingItem);
        } else if (selectedItem) {
          console.log("xxx11111");

          newList.push({
            ...selectedItem,
            certImage: [],
            endTime: "",
            startTime: "",
          });
        }
      });
      console.log(newList, "newListnewList");
      // 删除 equityClassifyList 中不在 e 数组中的项目
      this.formData.equityClassifyList = newList.filter((item) =>
        e.includes(item.id)
      );
      // this.formData.equityClassifyList = newList;
    },
  },
};
</script>
<style scoped>
.video_box {
  margin-left: 3%;
  margin-bottom: 10px;
}
.video_icon {
  cursor: pointer;
  margin-top: 0.5%;
  margin-left: 10px;
}
.video_span {
  width: 80px;
  margin-left: 10px;
}
/deep/.el-input__inner {
  text-align: left;
}
</style>
